import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box, Section, Image, Span } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia, Menu } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"team"} />
		<Helmet>
			<title>
				Non-Fungible Albums
			</title>
			<meta name={"description"} content={"Welcome to a better universe for art and artists."} />
			<meta property={"og:title"} content={"Non-Fungible Albums"} />
			<meta property={"og:description"} content={"A future classic of the NFT space."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/untitled2_04.jpeg?v=2022-08-06T22:58:14.411Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"} />
			<meta name={"msapplication-TileColor"} content={"#04080C"} />
		</Helmet>
		<Section padding="40px 0 40px 0" quarkly-title="Header">
			<Override
				slot="SectionContent"
				flex-direction="row"
				align-items="center"
				display="flex"
				justify-content="space-between"
			/>
			<Box
				empty-border-color="LightGray"
				width="30%"
				align-items="center"
				lg-width="50%"
				empty-min-height="64px"
				empty-border-style="solid"
				display="flex"
				sm-width="70%"
				empty-min-width="64px"
				empty-border-width="1px"
			>
				<Text color="--light" margin="0px 0px 0px 0px" font="--headline3">
					<Link href="/index" border-color="#ffffff" color="#ffffff">
						Non-Fungible Albums
					</Link>
				</Text>
			</Box>
			<Box
				sm-width="30%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-style="solid"
				display="flex"
				align-items="center"
				empty-border-width="1px"
				empty-border-color="LightGray"
				width="70%"
				lg-width="50%"
			>
				<Components.QuarklycommunityKitMobileSidePanel>
					<Override slot="Content" justify-content="flex-end" background="--color-dark" padding="0px 0px 0px 0px" />
					<Override slot="Button Text" md-color="--light" md-font="--base" md-text-transform="uppercase">
						go
					</Override>
					<Override slot="Button Icon" md-color="--light" />
					<Box
						md-padding="20px 0px 20px 0px"
						display="flex"
						justify-content="flex-end"
						align-items="center"
						md-flex-direction="column"
					>
						<Link href="https://twitter.com/nonfngblealbums" color="#ffffff" padding="0px 10px 0px 10px">
							Twitter
						</Link>
						<Link href="https://discord.com/invite/NwFfUbuhks" color="#ffffff" padding="0px 10px 0px 10px">
							Discord
						</Link>
						<Link href="https://opensea.io/nonfungiblealbums?tab=created" color="#ffffff" padding="0px 10px 0px 10px">
							OpenSea
						</Link>
						<Link href="https://open.spotify.com/artist/33hDvJkOZe1zuhg43TxWBX?si=j3fqyl5HRS63hZl7TP-Apg" color="#ffffff" padding="0px 10px 0px 10px">
							Spotify
						</Link>
					</Box>
				</Components.QuarklycommunityKitMobileSidePanel>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0">
			<Box
				align-items="center"
				display="flex"
				justify-content="center"
				flex-direction="column"
				margin="0px 0px 32px 0px"
				width="100%"
			>
				<Text
					margin="0px 0px 0px 0px"
					font="--headline1"
					color="--light"
					sm-text-align="center"
					text-align="center"
				>
					Our Team
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					font="--lead"
					display="block"
					width="50%"
					text-align="center"
					color="--lightD2"
					lg-width="100%"
				>
					We have a doxxed team, dyor.
				</Text>
			</Box>
			<Box
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				md-grid-template-columns="1fr"
				width="100%"
			>
				<Box position="relative" display="flex" flex-direction="column">
					<Box
						width="100%"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						padding="100% 0px 0px 0px"
						border-radius="16px"
					>
						<Image
							src="https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/0x.jpeg?v=2022-08-10T07:15:19.176Z"
							border-radius="16px"
							width="100%"
							object-fit="cover"
							position="absolute"
							top={0}
							left={0}
							bottom={0}
							right={0}
							max-height="100%"
							height="420px"
							md-height="100%"
							srcSet="https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/0x.jpeg?v=2022-08-10T07%3A15%3A19.176Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/0x.jpeg?v=2022-08-10T07%3A15%3A19.176Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/0x.jpeg?v=2022-08-10T07%3A15%3A19.176Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/0x.jpeg?v=2022-08-10T07%3A15%3A19.176Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/0x.jpeg?v=2022-08-10T07%3A15%3A19.176Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/0x.jpeg?v=2022-08-10T07%3A15%3A19.176Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/0x.jpeg?v=2022-08-10T07%3A15%3A19.176Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							position="absolute"
							width="100%"
							height="auto"
							background="linear-gradient(180deg, #0029FF 0%, rgba(255, 0, 229, 0.24) 100%)"
							opacity=".3"
							bottom="0px"
							top="0px"
							right="0px"
							left="0px"
							border-radius="16px"
						/>
					</Box>
					<Box padding="0px 20px 0px 20px" margin="0px 0px 16px 0px">
						<Text
							margin="24px 0px 0px 0px"
							font="--headline3"
							display="block"
							text-align="center"
							color="--lightD2"
						>
							<Span
								color="#ffffff"
								link-color="#cacaff"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								<Span
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									<Link href="https://mkucuk9.wixsite.com/main">
										Mahir Can Küçük
									</Link>
								</Span>
							</Span>
						</Text>
						<Text
							margin="16px 0px 0px 0px"
							font="normal 500 14px/1.2 --fontFamily-sans"
							display="block"
							text-align="center"
							color="--grey"
							letter-spacing="2.8px"
						>
							Music / Music Production
						</Text>
						<Text
							margin="16px 0px 0px 0px"
							font="normal 300 18px/1.5 --fontFamily-sans"
							display="block"
							text-align="center"
							color="--grey"
						>
							In the real world, Mahir is the same person as Can Artsan.
						</Text>
					</Box>
					<SocialMedia facebook="https://www.facebook.com/quarklyapp/" instagram="https://instagram.com/instagram" margin="12px 0px 24px 0px">
						<Override
							slot="link"
							border-radius="50%"
							color="--grey"
							hover-color="--light"
							background="transparent"
							hover-background="transparent"
							margin="0 5px 0 5px"
							padding="0px 0px 0px 0px"
							href="https://facebook.com/cankucuk17"
						/>
						<Override slot="icon-facebook" color="#000000" />
						<Override
							slot="icon"
							color="--darkL1"
							padding="10px 10px 10px 10px"
							border-color="--color-grey"
							border-style="solid"
							size="40px"
							border-radius="50px"
							background="--color-lightD2"
							border-width="0px"
						/>
						<Override slot="link-facebook" href="https://facebook.com/cankucuk17" />
						<Override slot="link-instagram" href="https://facebook.com/cankucuk17" />
					</SocialMedia>
				</Box>
				<Box position="relative" display="flex" flex-direction="column">
					<Box
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						padding="100% 0px 0px 0px"
						border-radius="16px"
					>
						<Image
							src="https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Ekran%20Resmi%202022-08-08%2010.15.30.jpg?v=2022-08-08T07:16:34.128Z"
							border-radius="16px"
							width="100%"
							object-fit="cover"
							position="absolute"
							top={0}
							left={0}
							bottom={0}
							right={0}
							max-height="100%"
							padding="0 0px 0px 0px"
							height="420px"
							md-height="100%"
							srcSet="https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Ekran%20Resmi%202022-08-08%2010.15.30.jpg?v=2022-08-08T07%3A16%3A34.128Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Ekran%20Resmi%202022-08-08%2010.15.30.jpg?v=2022-08-08T07%3A16%3A34.128Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Ekran%20Resmi%202022-08-08%2010.15.30.jpg?v=2022-08-08T07%3A16%3A34.128Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Ekran%20Resmi%202022-08-08%2010.15.30.jpg?v=2022-08-08T07%3A16%3A34.128Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Ekran%20Resmi%202022-08-08%2010.15.30.jpg?v=2022-08-08T07%3A16%3A34.128Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Ekran%20Resmi%202022-08-08%2010.15.30.jpg?v=2022-08-08T07%3A16%3A34.128Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Ekran%20Resmi%202022-08-08%2010.15.30.jpg?v=2022-08-08T07%3A16%3A34.128Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							position="absolute"
							width="100%"
							height="auto"
							background="linear-gradient(180deg, #0029FF 0%, rgba(255, 0, 229, 0.24) 100%)"
							opacity=".3"
							bottom="0px"
							top="0px"
							right="0px"
							left="0px"
							border-radius="16px"
						/>
					</Box>
					<Box padding="0px 20px 0px 20px" margin="0px 0px 16px 0px">
						<Text
							margin="24px 0px 0px 0px"
							font="--headline3"
							display="block"
							text-align="center"
							color="--lightD2"
						>
							<Link href="https://lynkfire.com/can" color="#ffffff" link-color="#cacaff">
								Can Artsan
							</Link>
						</Text>
						<Text
							margin="16px 0px 0px 0px"
							font="normal 500 14px/1.2 --fontFamily-sans"
							display="block"
							text-align="center"
							color="--grey"
							letter-spacing="2.8px"
						>
							3D Art / Animation / Concept Design
						</Text>
						<Text
							margin="16px 0px 0px 0px"
							font="normal 300 18px/1.5 --fontFamily-sans"
							display="block"
							text-align="center"
							color="--grey"
						>
							Visual artist alter ego of Mahir Can Küçük that lives in Web3.
						</Text>
					</Box>
					<SocialMedia twitter="https://twitter.com/canartsan" instagram="https://instagram.com/canartsan" margin="12px 0px 24px 0px">
						<Override
							slot="link"
							border-radius="50%"
							color="--grey"
							hover-color="--light"
							background="transparent"
							hover-background="transparent"
							margin="0 5px 0 5px"
							padding="0px 0px 0px 0px"
						/>
						<Override slot="icon-facebook" color="#000000" />
						<Override
							slot="icon"
							color="--darkL1"
							padding="10px 10px 10px 10px"
							border-color="--color-grey"
							border-style="solid"
							size="40px"
							border-radius="50px"
							background="--color-lightD2"
							border-width="0px"
						/>
					</SocialMedia>
				</Box>
				<Box position="relative" display="flex" flex-direction="column">
					<Box
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						padding="100% 0px 0px 0px"
						border-radius="16px"
					>
						<Image
							src="https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/0y.jpeg?v=2022-08-10T07:15:19.181Z"
							border-radius="16px"
							width="100%"
							object-fit="cover"
							position="absolute"
							top={0}
							left={0}
							bottom={0}
							right={0}
							max-height="100%"
							height="420px"
							md-height="100%"
							srcSet="https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/0y.jpeg?v=2022-08-10T07%3A15%3A19.181Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/0y.jpeg?v=2022-08-10T07%3A15%3A19.181Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/0y.jpeg?v=2022-08-10T07%3A15%3A19.181Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/0y.jpeg?v=2022-08-10T07%3A15%3A19.181Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/0y.jpeg?v=2022-08-10T07%3A15%3A19.181Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/0y.jpeg?v=2022-08-10T07%3A15%3A19.181Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/0y.jpeg?v=2022-08-10T07%3A15%3A19.181Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							position="absolute"
							width="100%"
							height="auto"
							background="linear-gradient(180deg, #0029FF 0%, rgba(255, 0, 229, 0.24) 100%)"
							opacity=".3"
							bottom="0px"
							top="0px"
							right="0px"
							left="0px"
							border-radius="16px"
						/>
					</Box>
					<Text
						margin="24px 0px 0px 0px"
						font="--headline3"
						display="block"
						text-align="center"
						color="--lightD2"
					>
						<Link href="https://behance.net/merttanatmis" color="#ffffff" link-color="#cacaff">
							Mert Tanatmis
						</Link>
					</Text>
					<Box padding="0px 20px 0px 20px" margin="0px 0px 16px 0px">
						<Text
							margin="16px 0px 0px 0px"
							font="normal 500 14px/1.2 --fontFamily-sans"
							display="block"
							text-align="center"
							color="--grey"
							letter-spacing="2.8px"
						>
							2D Design
						</Text>
						<Text
							margin="16px 0px 0px 0px"
							font="normal 300 18px/1.5 --fontFamily-sans"
							display="block"
							text-align="center"
							color="--grey"
						>
							What a great human Mert is. Old friend of Can / Mahir.
						</Text>
					</Box>
					<SocialMedia twitter="https://twitter.com/merttanatmis" instagram="https://instagram.com/merttanatmis" margin="12px 0px 24px 0px">
						<Override
							slot="link"
							border-radius="50%"
							color="--grey"
							hover-color="--light"
							background="transparent"
							hover-background="transparent"
							margin="0 5px 0 5px"
							padding="0px 0px 0px 0px"
						/>
						<Override slot="icon-facebook" color="#000000" />
						<Override
							slot="icon"
							color="--darkL1"
							padding="10px 10px 10px 10px"
							border-color="--color-grey"
							border-style="solid"
							size="40px"
							border-radius="50px"
							background="--color-lightD2"
							border-width="0px"
						/>
					</SocialMedia>
				</Box>
			</Box>
		</Section>
		<Text margin="0px 0px 0px 0px" color="#ffffff" font="200 18px --fontFamily-sans" text-align="center">
			Non-Fungible Albums' website is powered by{" "}
			<Link
				href="https://quarkly.io"
				color="#ffffff"
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				Quarkly
			</Link>
			. Thank you, Artem and Q.
		</Text>
		<Section background-color="--dark" text-align="center" padding="32px 0">
			<Menu
				display="flex"
				justify-content="center"
				font="--lead"
				font-weight="700"
				margin="-6px 0 16px"
				md-flex-direction="column"
				md-align-items="center"
			>
				<Override slot="link" text-decoration="none" color="--light" padding="6px 12px" />
				<Override slot="link-active" color="--primary" />
				<Override slot="item" padding="6px 0px" />
			</Menu>
			<Link
				href="mailto:team@nonfungiblealbums.io"
				text-decoration-line="none"
				variant="--base"
				color="--grey"
				hover-color="--primary"
				font="400 16px --fontFamily-sans"
				lg-color="#b1c1d1"
				lg-font="300 16px --fontFamily-sans"
			>
				team@nonfungiblealbums.io
			</Link>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"60da14fa3f4eb1001ea27687"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<style place={"endOfBody"} rawKey={"62f4bcf3ed0f76d8601086b7"}>
				{"a[href=\"https://quarkly.io/\"]{\ndisplay: none;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});