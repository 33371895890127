export default {
	"pages": {
		"root": {
			"pageUrl": "root",
			"name": "root",
			"children": [
				"62e84f555e5c6e0021553bbf",
				"62e84f555e5c6e0021553bc3",
				"62ecc22b95861b00203a987c",
				"62ecd3e9f45b7e002478be03",
				"62eeeeb9fedca4001e34b1d9"
			],
			"id": "root"
		},
		"62e84f555e5c6e0021553bbf": {
			"id": "62e84f555e5c6e0021553bbf",
			"name": "404",
			"pageUrl": "404"
		},
		"62e84f555e5c6e0021553bc3": {
			"id": "62e84f555e5c6e0021553bc3",
			"name": "Home",
			"pageUrl": "index",
			"seo": {
				"title": "Non-Fungible Albums",
				"description": "A Future Classic for The NFT World."
			},
			"styles": {
				"background": "#010000"
			}
		},
		"62ecc22b95861b00203a987c": {
			"id": "62ecc22b95861b00203a987c",
			"pageUrl": "covers",
			"name": "The Album Covers"
		},
		"62ecd3e9f45b7e002478be03": {
			"id": "62ecd3e9f45b7e002478be03",
			"pageUrl": "news-interviews",
			"name": "News and Interviews"
		},
		"62eeeeb9fedca4001e34b1d9": {
			"id": "62eeeeb9fedca4001e34b1d9",
			"pageUrl": "team",
			"name": "Team"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {
			"background": "--color-dark"
		},
		"seo": {
			"title": "Non-Fungible Albums",
			"og:image": "https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/untitled2_04.jpeg?v=2022-08-06T22:58:14.411Z",
			"og:title": "Non-Fungible Albums",
			"favicon_32px": "https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z",
			"favicon_win10_270px": "https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z",
			"background_win10": "#04080C",
			"description": "Welcome to a better universe for art and artists.",
			"og:description": "A future classic of the NFT space.",
			"favicon_apple_152px": "https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"
		}
	}
}