import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, Menu } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"covers"} />
		<Helmet>
			<title>
				Non-Fungible Albums
			</title>
			<meta name={"description"} content={"Welcome to a better universe for art and artists."} />
			<meta property={"og:title"} content={"Non-Fungible Albums"} />
			<meta property={"og:description"} content={"A future classic of the NFT space."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/untitled2_04.jpeg?v=2022-08-06T22:58:14.411Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"} />
			<meta name={"msapplication-TileColor"} content={"#04080C"} />
		</Helmet>
		<Section padding="40px 0 40px 0" quarkly-title="Header">
			<Override
				slot="SectionContent"
				flex-direction="row"
				align-items="center"
				display="flex"
				justify-content="space-between"
			/>
			<Box
				empty-border-color="LightGray"
				width="30%"
				align-items="center"
				lg-width="50%"
				empty-min-height="64px"
				empty-border-style="solid"
				display="flex"
				sm-width="70%"
				empty-min-width="64px"
				empty-border-width="1px"
			>
				<Text color="--light" margin="0px 0px 0px 0px" font="--headline3">
					<Link href="/index" border-color="#ffffff" color="#ffffff">
						Non-Fungible Albums
					</Link>
				</Text>
			</Box>
			<Box
				sm-width="30%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-style="solid"
				display="flex"
				align-items="center"
				empty-border-width="1px"
				empty-border-color="LightGray"
				width="70%"
				lg-width="50%"
			>
				<Components.QuarklycommunityKitMobileSidePanel>
					<Override slot="Content" justify-content="flex-end" background="--color-dark" padding="0px 0px 0px 0px" />
					<Override slot="Button Text" md-color="--light" md-font="--base" md-text-transform="uppercase">
						go
					</Override>
					<Override slot="Button Icon" md-color="--light" />
					<Box
						md-padding="20px 0px 20px 0px"
						display="flex"
						justify-content="flex-end"
						align-items="center"
						md-flex-direction="column"
					>
						<Link href="https://twitter.com/nonfngblealbums" color="#ffffff" padding="0px 10px 0px 10px">
							Twitter
						</Link>
						<Link href="https://discord.com/invite/NwFfUbuhks" color="#ffffff" padding="0px 10px 0px 10px">
							Discord
						</Link>
						<Link href="https://opensea.io/nonfungiblealbums?tab=created" color="#ffffff" padding="0px 10px 0px 10px">
							OpenSea
						</Link>
						<Link href="https://open.spotify.com/artist/33hDvJkOZe1zuhg43TxWBX?si=j3fqyl5HRS63hZl7TP-Apg" color="#ffffff" padding="0px 10px 0px 10px">
							Spotify
						</Link>
					</Box>
				</Components.QuarklycommunityKitMobileSidePanel>
			</Box>
		</Section>
		<Section padding="88px 0 88px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0">
			<Override slot="SectionContent" max-width="1220px" />
			<Box margin="0px 0px 100px 0px" padding="15px 15px 15px 15px" md-margin="0px 0px 40px 0px" lg-margin="0px 0px 56px 0px">
				<Text
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--light"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					border-color="#ffffff"
				>
					The Album Covers Collection
				</Text>
				<Text margin="0px 0px 0px 0px" font="--base" color="--lightD2" text-align="center">
					The first follow-up collection to{" "}
					<Link
						href="#https://opensea.io/collection/nonfungiblecassettes"
						color="#ffffff"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Non-Fungible Cassettes
					</Link>
					{" "}and{" "}
					<Link href="#https://opensea.io/collection/nonfungiblecds" color="#ffffff">
						Non-Fungible CDs
					</Link>
					, the second step of our roadmap, will be the collection of the cover arts of the albums. The album covers will be created by prestigious visual artists from the NFT space!
				</Text>
			</Box>
			<Box
				display="flex"
				margin="0px 0px 49px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
			>
				<Box
					width="30%"
					padding="48px 15px 15px 15px"
					align-items="flex-start"
					display="flex"
					justify-content="center"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					margin="0px 10% 0px 0px"
					lg-margin="0px 30px 0px 0px"
					md-width="100%"
					sm-padding="24px 15px 15px 15px"
					md-justify-content="flex-start"
					md-margin="0px 0px 0px 0px"
					sm-justify-content="center"
					text-align="center"
				>
					<Text margin="0px 0px 0px 0px" font="--headline3" color="--light" text-align="left">
						01 - Endless{"              "}
					</Text>
				</Box>
				<Box
					width="70%"
					padding="48px 15px 15px 15px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					md-width="100%"
					md-border-width="0px"
					sm-align-items="center"
					sm-flex-direction="column"
					sm-justify-content="center"
					sm-padding="25px 15px 15px 15px"
					md-padding="33px 15px 15px 15px"
				>
					<Image
						width="217px"
						height="217px"
						margin="0px 64px 0px 0px"
						src="https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/01.jpeg?v=2022-08-07T22:08:46.552Z"
						object-fit="cover"
						border-radius="100%"
						lg-width="124px"
						lg-height="124px"
						lg-margin="0px 24px 0px 0px"
						sm-margin="0px 0px 24px 0px"
						background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/01.jpeg?v=2022-08-07T22:08:46.552Z) 0% 0% /contain repeat scroll padding-box"
						srcSet="https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/01.jpeg?v=2022-08-07T22%3A08%3A46.552Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/01.jpeg?v=2022-08-07T22%3A08%3A46.552Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/01.jpeg?v=2022-08-07T22%3A08%3A46.552Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/01.jpeg?v=2022-08-07T22%3A08%3A46.552Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/01.jpeg?v=2022-08-07T22%3A08%3A46.552Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/01.jpeg?v=2022-08-07T22%3A08%3A46.552Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/01.jpeg?v=2022-08-07T22%3A08%3A46.552Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						padding="0px 0px 0px 0px"
						width="50%"
						lg-width="70%"
						sm-width="100%"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light" lg-color="#ffffff">
							Andre Vieira
						</Text>
						<Link href="https://twitter.com/andrevieiraart/status/1529425623487238145" color="#ffffff" font="20px sans-serif">
							View their own announcement of being a part of our project.
						</Link>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				margin="0px 0px 49px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
			>
				<Box
					width="30%"
					padding="48px 15px 15px 15px"
					align-items="flex-start"
					display="flex"
					justify-content="center"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					margin="0px 10% 0px 0px"
					lg-margin="0px 30px 0px 0px"
					md-width="100%"
					sm-padding="24px 15px 15px 15px"
					md-justify-content="flex-start"
					md-margin="0px 0px 0px 0px"
					sm-justify-content="center"
				>
					<Text margin="0px 0px 0px 0px" font="--headline3" color="--light" text-align="left">
						02 - Sapphire Rain{"      "}
					</Text>
				</Box>
				<Box
					width="70%"
					padding="48px 15px 15px 15px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					md-width="100%"
					md-border-width="0px"
					sm-align-items="center"
					sm-flex-direction="column"
					sm-justify-content="center"
					sm-padding="25px 15px 15px 15px"
					md-padding="33px 15px 15px 15px"
				>
					<Image
						width="217px"
						height="217px"
						margin="0px 64px 0px 0px"
						src="https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/02.png?v=2022-08-07T22:08:46.593Z"
						object-fit="cover"
						border-radius="100%"
						lg-width="124px"
						lg-height="124px"
						lg-margin="0px 24px 0px 0px"
						sm-margin="0px 0px 24px 0px"
						srcSet="https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/02.png?v=2022-08-07T22%3A08%3A46.593Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/02.png?v=2022-08-07T22%3A08%3A46.593Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/02.png?v=2022-08-07T22%3A08%3A46.593Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/02.png?v=2022-08-07T22%3A08%3A46.593Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/02.png?v=2022-08-07T22%3A08%3A46.593Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/02.png?v=2022-08-07T22%3A08%3A46.593Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/02.png?v=2022-08-07T22%3A08%3A46.593Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						padding="0px 0px 0px 0px"
						width="50%"
						lg-width="70%"
						sm-width="100%"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light" lg-color="#ffffff">
							Dolce Paganne
						</Text>
						<Link color="#ffffff" href="https://twitter.com/DolcePaganne/status/1519282874222010368" font="20px sans-serif">
							View their own announcement of being a part of our project.
						</Link>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				margin="0px 0px 49px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
			>
				<Box
					width="30%"
					padding="48px 15px 15px 15px"
					align-items="flex-start"
					display="flex"
					justify-content="center"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					margin="0px 10% 0px 0px"
					lg-margin="0px 30px 0px 0px"
					md-width="100%"
					sm-padding="24px 15px 15px 15px"
					md-justify-content="flex-start"
					md-margin="0px 0px 0px 0px"
					sm-justify-content="center"
				>
					<Text margin="0px 0px 0px 0px" font="--headline3" color="--light" text-align="left">
						03 - Kinky Romance
					</Text>
				</Box>
				<Box
					width="70%"
					padding="48px 15px 15px 15px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					md-width="100%"
					md-border-width="0px"
					sm-align-items="center"
					sm-flex-direction="column"
					sm-justify-content="center"
					sm-padding="25px 15px 15px 15px"
					md-padding="33px 15px 15px 15px"
				>
					<Image
						width="217px"
						height="217px"
						margin="0px 64px 0px 0px"
						src="https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/03.jpeg?v=2022-08-07T22:08:46.573Z"
						object-fit="cover"
						border-radius="100%"
						lg-width="124px"
						lg-height="124px"
						lg-margin="0px 24px 0px 0px"
						sm-margin="0px 0px 24px 0px"
						srcSet="https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/03.jpeg?v=2022-08-07T22%3A08%3A46.573Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/03.jpeg?v=2022-08-07T22%3A08%3A46.573Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/03.jpeg?v=2022-08-07T22%3A08%3A46.573Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/03.jpeg?v=2022-08-07T22%3A08%3A46.573Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/03.jpeg?v=2022-08-07T22%3A08%3A46.573Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/03.jpeg?v=2022-08-07T22%3A08%3A46.573Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/03.jpeg?v=2022-08-07T22%3A08%3A46.573Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						padding="0px 0px 0px 0px"
						width="50%"
						lg-width="70%"
						sm-width="100%"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light" lg-color="#ffffff">
							Rızacan Kumaş
						</Text>
						<Link color="#ffffff" href="https://twitter.com/KumasRizacan/status/1558438489053601792" font="20px sans-serif">
							View their own announcement of being a part of our project.
						</Link>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				margin="0px 0px 49px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
			>
				<Box
					width="30%"
					padding="48px 15px 15px 15px"
					align-items="flex-start"
					display="flex"
					justify-content="center"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					margin="0px 10% 0px 0px"
					lg-margin="0px 30px 0px 0px"
					md-width="100%"
					sm-padding="24px 15px 15px 15px"
					md-justify-content="flex-start"
					md-margin="0px 0px 0px 0px"
					sm-justify-content="center"
				>
					<Text margin="0px 0px 0px 0px" font="--headline3" color="--light" text-align="left">
						04 - Late Promises{"   "}
					</Text>
				</Box>
				<Box
					width="70%"
					padding="48px 15px 15px 15px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					md-width="100%"
					md-border-width="0px"
					sm-align-items="center"
					sm-flex-direction="column"
					sm-justify-content="center"
					sm-padding="25px 15px 15px 15px"
					md-padding="33px 15px 15px 15px"
				>
					<Image
						width="217px"
						height="217px"
						margin="0px 64px 0px 0px"
						src="https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/04x.jpeg?v=2022-08-07T22:08:46.563Z"
						object-fit="cover"
						border-radius="100%"
						lg-width="124px"
						lg-height="124px"
						lg-margin="0px 24px 0px 0px"
						sm-margin="0px 0px 24px 0px"
						srcSet="https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/04x.jpeg?v=2022-08-07T22%3A08%3A46.563Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/04x.jpeg?v=2022-08-07T22%3A08%3A46.563Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/04x.jpeg?v=2022-08-07T22%3A08%3A46.563Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/04x.jpeg?v=2022-08-07T22%3A08%3A46.563Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/04x.jpeg?v=2022-08-07T22%3A08%3A46.563Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/04x.jpeg?v=2022-08-07T22%3A08%3A46.563Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/04x.jpeg?v=2022-08-07T22%3A08%3A46.563Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						padding="0px 0px 0px 0px"
						width="50%"
						lg-width="70%"
						sm-width="100%"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light" lg-color="#ffffff">
							Doruk Seymen
						</Text>
						<Link color="#ffffff" href="https://twitter.com/DorukSeymen/status/1521811219820199936" font="20px sans-serif">
							View their own announcement of being a part of our project.
						</Link>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				margin="0px 0px 49px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
			>
				<Box
					width="30%"
					padding="48px 15px 15px 15px"
					align-items="flex-start"
					display="flex"
					justify-content="center"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					margin="0px 10% 0px 0px"
					lg-margin="0px 30px 0px 0px"
					md-width="100%"
					sm-padding="24px 15px 15px 15px"
					md-justify-content="flex-start"
					md-margin="0px 0px 0px 0px"
					sm-justify-content="center"
				>
					<Text margin="0px 0px 0px 0px" font="--headline3" color="--light" text-align="left">
						05 - Do You See Me Now?
					</Text>
				</Box>
				<Box
					width="70%"
					padding="48px 15px 15px 15px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					md-width="100%"
					md-border-width="0px"
					sm-align-items="center"
					sm-flex-direction="column"
					sm-justify-content="center"
					sm-padding="25px 15px 15px 15px"
					md-padding="33px 15px 15px 15px"
				>
					<Image
						width="217px"
						height="217px"
						margin="0px 64px 0px 0px"
						src="https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/05.jpeg?v=2022-08-07T22:08:46.546Z"
						object-fit="cover"
						border-radius="100%"
						lg-width="124px"
						lg-height="124px"
						lg-margin="0px 24px 0px 0px"
						sm-margin="0px 0px 24px 0px"
						srcSet="https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/05.jpeg?v=2022-08-07T22%3A08%3A46.546Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/05.jpeg?v=2022-08-07T22%3A08%3A46.546Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/05.jpeg?v=2022-08-07T22%3A08%3A46.546Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/05.jpeg?v=2022-08-07T22%3A08%3A46.546Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/05.jpeg?v=2022-08-07T22%3A08%3A46.546Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/05.jpeg?v=2022-08-07T22%3A08%3A46.546Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/05.jpeg?v=2022-08-07T22%3A08%3A46.546Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						padding="0px 0px 0px 0px"
						width="50%"
						lg-width="70%"
						sm-width="100%"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light" lg-color="#ffffff">
							Kutay Can Doğan
						</Text>
						<Link color="#ffffff" href="https://twitter.com/KutayCanDogan/status/1524773118820159488" font="20px sans-serif">
							View their own announcement of being a part of our project.
						</Link>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				margin="0px 0px 49px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
			>
				<Box
					width="30%"
					padding="48px 15px 15px 15px"
					align-items="flex-start"
					display="flex"
					justify-content="center"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					margin="0px 10% 0px 0px"
					lg-margin="0px 30px 0px 0px"
					md-width="100%"
					sm-padding="24px 15px 15px 15px"
					md-justify-content="flex-start"
					md-margin="0px 0px 0px 0px"
					sm-justify-content="center"
				>
					<Text margin="0px 0px 0px 0px" font="--headline3" color="--light" text-align="left">
						06 - Jamming In Mars
					</Text>
				</Box>
				<Box
					width="70%"
					padding="48px 15px 15px 15px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					md-width="100%"
					md-border-width="0px"
					sm-align-items="center"
					sm-flex-direction="column"
					sm-justify-content="center"
					sm-padding="25px 15px 15px 15px"
					md-padding="33px 15px 15px 15px"
				>
					<Image
						width="217px"
						height="217px"
						margin="0px 64px 0px 0px"
						src="https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Ekran%20Resmi%202022-08-19%2011.40.15.png?v=2022-08-19T08:41:19.716Z"
						object-fit="cover"
						border-radius="100%"
						lg-width="124px"
						lg-height="124px"
						lg-margin="0px 24px 0px 0px"
						sm-margin="0px 0px 24px 0px"
						srcSet="https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Ekran%20Resmi%202022-08-19%2011.40.15.png?v=2022-08-19T08%3A41%3A19.716Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Ekran%20Resmi%202022-08-19%2011.40.15.png?v=2022-08-19T08%3A41%3A19.716Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Ekran%20Resmi%202022-08-19%2011.40.15.png?v=2022-08-19T08%3A41%3A19.716Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Ekran%20Resmi%202022-08-19%2011.40.15.png?v=2022-08-19T08%3A41%3A19.716Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Ekran%20Resmi%202022-08-19%2011.40.15.png?v=2022-08-19T08%3A41%3A19.716Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Ekran%20Resmi%202022-08-19%2011.40.15.png?v=2022-08-19T08%3A41%3A19.716Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Ekran%20Resmi%202022-08-19%2011.40.15.png?v=2022-08-19T08%3A41%3A19.716Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						padding="0px 0px 0px 0px"
						width="50%"
						lg-width="70%"
						sm-width="100%"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light" lg-color="#ffffff">
							Berk Tarakçıoğlu
						</Text>
						<Link color="#ffffff" href="https://twitter.com/theotostopcu/status/1560551551231758338" font="20px sans-serif">
							View their own announcement of being a part of our project.
						</Link>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				margin="0px 0px 49px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
			>
				<Box
					width="30%"
					padding="48px 15px 15px 15px"
					align-items="flex-start"
					display="flex"
					justify-content="center"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					margin="0px 10% 0px 0px"
					lg-margin="0px 30px 0px 0px"
					md-width="100%"
					sm-padding="24px 15px 15px 15px"
					md-justify-content="flex-start"
					md-margin="0px 0px 0px 0px"
					sm-justify-content="center"
				>
					<Text margin="0px 0px 0px 0px" font="--headline3" color="--light" text-align="left">
						07 - Keeping Me Up
					</Text>
				</Box>
				<Box
					width="70%"
					padding="48px 15px 15px 15px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					md-width="100%"
					md-border-width="0px"
					sm-align-items="center"
					sm-flex-direction="column"
					sm-justify-content="center"
					sm-padding="25px 15px 15px 15px"
					md-padding="33px 15px 15px 15px"
				>
					<Image
						width="217px"
						height="217px"
						margin="0px 64px 0px 0px"
						src="https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/IMG_0516.jpeg?v=2022-08-23T14:30:32.378Z"
						object-fit="cover"
						border-radius="100%"
						lg-width="124px"
						lg-height="124px"
						lg-margin="0px 24px 0px 0px"
						sm-margin="0px 0px 24px 0px"
						srcSet="https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/IMG_0516.jpeg?v=2022-08-23T14%3A30%3A32.378Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/IMG_0516.jpeg?v=2022-08-23T14%3A30%3A32.378Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/IMG_0516.jpeg?v=2022-08-23T14%3A30%3A32.378Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/IMG_0516.jpeg?v=2022-08-23T14%3A30%3A32.378Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/IMG_0516.jpeg?v=2022-08-23T14%3A30%3A32.378Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/IMG_0516.jpeg?v=2022-08-23T14%3A30%3A32.378Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/IMG_0516.jpeg?v=2022-08-23T14%3A30%3A32.378Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						padding="0px 0px 0px 0px"
						width="50%"
						lg-width="70%"
						sm-width="100%"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light" lg-color="#ffffff">
							Seher Kış
						</Text>
						<Link color="#ffffff" font="20px sans-serif" href="https://twitter.com/seerkis/status/1562091335867408385">
							View their own announcement of being a part of our project.
						</Link>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				margin="0px 0px 49px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
			>
				<Box
					width="30%"
					padding="48px 15px 15px 15px"
					align-items="flex-start"
					display="flex"
					justify-content="center"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					margin="0px 10% 0px 0px"
					lg-margin="0px 30px 0px 0px"
					md-width="100%"
					sm-padding="24px 15px 15px 15px"
					md-justify-content="flex-start"
					md-margin="0px 0px 0px 0px"
					sm-justify-content="center"
				>
					<Text margin="0px 0px 0px 0px" font="--headline3" color="--light" text-align="left">
						08 - You Are Wild{"       "}
					</Text>
				</Box>
				<Box
					width="70%"
					padding="48px 15px 15px 15px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					md-width="100%"
					md-border-width="0px"
					sm-align-items="center"
					sm-flex-direction="column"
					sm-justify-content="center"
					sm-padding="25px 15px 15px 15px"
					md-padding="33px 15px 15px 15px"
				>
					<Image
						width="217px"
						height="217px"
						margin="0px 64px 0px 0px"
						src="https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/axstone%20pfp.png?v=2022-08-25T09:24:53.616Z"
						object-fit="cover"
						border-radius="100%"
						lg-width="124px"
						lg-height="124px"
						lg-margin="0px 24px 0px 0px"
						sm-margin="0px 0px 24px 0px"
						srcSet="https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/axstone%20pfp.png?v=2022-08-25T09%3A24%3A53.616Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/axstone%20pfp.png?v=2022-08-25T09%3A24%3A53.616Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/axstone%20pfp.png?v=2022-08-25T09%3A24%3A53.616Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/axstone%20pfp.png?v=2022-08-25T09%3A24%3A53.616Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/axstone%20pfp.png?v=2022-08-25T09%3A24%3A53.616Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/axstone%20pfp.png?v=2022-08-25T09%3A24%3A53.616Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/axstone%20pfp.png?v=2022-08-25T09%3A24%3A53.616Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						padding="0px 0px 0px 0px"
						width="50%"
						lg-width="70%"
						sm-width="100%"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light" lg-color="#ffffff">
							Axstone
						</Text>
						<Link color="#ffffff" font="20px sans-serif" href="https://twitter.com/axstonee/status/1562775204933222400">
							View their own announcement of being a part of our project.
						</Link>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				margin="0px 0px 49px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
			>
				<Box
					width="30%"
					padding="48px 15px 15px 15px"
					align-items="flex-start"
					display="flex"
					justify-content="center"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					margin="0px 10% 0px 0px"
					lg-margin="0px 30px 0px 0px"
					md-width="100%"
					sm-padding="24px 15px 15px 15px"
					md-justify-content="flex-start"
					md-margin="0px 0px 0px 0px"
					sm-justify-content="center"
				>
					<Text margin="0px 0px 0px 0px" font="--headline3" color="--light" text-align="left">
						09 - Believe{"                "}
					</Text>
				</Box>
				<Box
					width="70%"
					padding="48px 15px 15px 15px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					md-width="100%"
					md-border-width="0px"
					sm-align-items="center"
					sm-flex-direction="column"
					sm-justify-content="center"
					sm-padding="25px 15px 15px 15px"
					md-padding="33px 15px 15px 15px"
				>
					<Image
						width="217px"
						height="217px"
						margin="0px 64px 0px 0px"
						src="https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Pfp%20-%20Selay_Karasu_2022.jpeg?v=2022-10-12T15:00:28.176Z"
						object-fit="cover"
						border-radius="100%"
						lg-width="124px"
						lg-height="124px"
						lg-margin="0px 24px 0px 0px"
						sm-margin="0px 0px 24px 0px"
						srcSet="https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Pfp%20-%20Selay_Karasu_2022.jpeg?v=2022-10-12T15%3A00%3A28.176Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Pfp%20-%20Selay_Karasu_2022.jpeg?v=2022-10-12T15%3A00%3A28.176Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Pfp%20-%20Selay_Karasu_2022.jpeg?v=2022-10-12T15%3A00%3A28.176Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Pfp%20-%20Selay_Karasu_2022.jpeg?v=2022-10-12T15%3A00%3A28.176Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Pfp%20-%20Selay_Karasu_2022.jpeg?v=2022-10-12T15%3A00%3A28.176Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Pfp%20-%20Selay_Karasu_2022.jpeg?v=2022-10-12T15%3A00%3A28.176Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Pfp%20-%20Selay_Karasu_2022.jpeg?v=2022-10-12T15%3A00%3A28.176Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						padding="0px 0px 0px 0px"
						width="50%"
						lg-width="70%"
						sm-width="100%"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light" lg-color="#ffffff">
							Selay Karasu
						</Text>
						<Link color="#ffffff" font="20px sans-serif" href="https://twitter.com/Selayout/status/1580229177755987980">
							View their own announcement of being a part of our project.
						</Link>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				margin="0px 0px 49px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
			>
				<Box
					width="30%"
					padding="48px 15px 15px 15px"
					align-items="flex-start"
					display="flex"
					justify-content="center"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					margin="0px 10% 0px 0px"
					lg-margin="0px 30px 0px 0px"
					md-width="100%"
					sm-padding="24px 15px 15px 15px"
					md-justify-content="flex-start"
					md-margin="0px 0px 0px 0px"
					sm-justify-content="center"
				>
					<Text margin="0px 0px 0px 0px" font="--headline3" color="--light" text-align="left">
						10 - We're Actually Young{"               "}
					</Text>
				</Box>
				<Box
					width="70%"
					padding="48px 15px 15px 15px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					md-width="100%"
					md-border-width="0px"
					sm-align-items="center"
					sm-flex-direction="column"
					sm-justify-content="center"
					sm-padding="25px 15px 15px 15px"
					md-padding="33px 15px 15px 15px"
				>
					<Image
						width="217px"
						height="217px"
						margin="0px 64px 0px 0px"
						src="https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/HouseOfMat.jpg?v=2022-10-26T13:10:40.773Z"
						object-fit="cover"
						border-radius="100%"
						lg-width="124px"
						lg-height="124px"
						lg-margin="0px 24px 0px 0px"
						sm-margin="0px 0px 24px 0px"
						srcSet="https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/HouseOfMat.jpg?v=2022-10-26T13%3A10%3A40.773Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/HouseOfMat.jpg?v=2022-10-26T13%3A10%3A40.773Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/HouseOfMat.jpg?v=2022-10-26T13%3A10%3A40.773Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/HouseOfMat.jpg?v=2022-10-26T13%3A10%3A40.773Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/HouseOfMat.jpg?v=2022-10-26T13%3A10%3A40.773Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/HouseOfMat.jpg?v=2022-10-26T13%3A10%3A40.773Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/HouseOfMat.jpg?v=2022-10-26T13%3A10%3A40.773Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						padding="0px 0px 0px 0px"
						width="50%"
						lg-width="70%"
						sm-width="100%"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light" lg-color="#ffffff">
							MΛT{"\n\n"}
						</Text>
						<Link color="#ffffff" font="20px sans-serif" href="https://twitter.com/houseofmat_/status/1585260931684003841">
							View their own announcement of being a part of our project.
						</Link>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section background-color="--dark" text-align="center" padding="32px 0">
			<Menu
				display="flex"
				justify-content="center"
				font="--lead"
				font-weight="700"
				margin="-6px 0 16px"
				md-flex-direction="column"
				md-align-items="center"
			>
				<Override slot="link" text-decoration="none" color="--light" padding="6px 12px" />
				<Override slot="link-active" color="--primary" />
				<Override slot="item" padding="6px 0px" />
			</Menu>
			<Link
				href="mailto:team@nonfungiblealbums.io"
				text-decoration-line="none"
				variant="--base"
				color="--grey"
				hover-color="--primary"
				lg-font="300 16px --fontFamily-sans"
				lg-color="#b1c1d1"
			>
				team@nonfungiblealbums.io
			</Link>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"60da14fa3f4eb1001ea27687"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<style place={"endOfBody"} rawKey={"62f4bcf3ed0f76d8601086b7"}>
				{"a[href=\"https://quarkly.io/\"]{\ndisplay: none;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});