import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, Menu } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Non-Fungible Albums
			</title>
			<meta name={"description"} content={"Welcome to a better universe for art and artists."} />
			<meta property={"og:title"} content={"Non-Fungible Albums"} />
			<meta property={"og:description"} content={"A future classic of the NFT space."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/untitled2_04.jpeg?v=2022-08-06T22:58:14.411Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"} />
			<meta name={"msapplication-TileColor"} content={"#04080C"} />
		</Helmet>
		<Section padding="40px 0 40px 0" quarkly-title="Header">
			<Override
				slot="SectionContent"
				flex-direction="row"
				align-items="center"
				display="flex"
				justify-content="space-between"
			/>
			<Box
				empty-border-color="LightGray"
				width="30%"
				align-items="center"
				lg-width="50%"
				empty-min-height="64px"
				empty-border-style="solid"
				display="flex"
				sm-width="70%"
				empty-min-width="64px"
				empty-border-width="1px"
			>
				<Text color="--light" margin="0px 0px 0px 0px" font="--headline3">
					<Link href="/index" border-color="#ffffff" color="#ffffff">
						Non-Fungible Albums
					</Link>
				</Text>
			</Box>
			<Box
				sm-width="30%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-style="solid"
				display="flex"
				align-items="center"
				empty-border-width="1px"
				empty-border-color="LightGray"
				width="70%"
				lg-width="50%"
			>
				<Components.QuarklycommunityKitMobileSidePanel>
					<Override slot="Content" justify-content="flex-end" background="--color-dark" padding="0px 0px 0px 0px" />
					<Override slot="Button Text" md-color="--light" md-font="--base" md-text-transform="uppercase">
						go
					</Override>
					<Override slot="Button Icon" md-color="--light" />
					<Box
						md-padding="20px 0px 20px 0px"
						display="flex"
						justify-content="flex-end"
						align-items="center"
						md-flex-direction="column"
					>
						<Link href="https://twitter.com/nonfngblealbums" color="#ffffff" padding="0px 10px 0px 10px">
							Twitter
						</Link>
						<Link href="https://discord.com/invite/NwFfUbuhks" color="#ffffff" padding="0px 10px 0px 10px">
							Discord
						</Link>
						<Link href="https://opensea.io/nonfungiblealbums?tab=created" color="#ffffff" padding="0px 10px 0px 10px">
							OpenSea
						</Link>
						<Link href="https://open.spotify.com/artist/33hDvJkOZe1zuhg43TxWBX?si=j3fqyl5HRS63hZl7TP-Apg" color="#ffffff" padding="0px 10px 0px 10px">
							Spotify
						</Link>
					</Box>
				</Components.QuarklycommunityKitMobileSidePanel>
			</Box>
		</Section>
		<Section padding="50px 0 100px 0" quarkly-title="HeroBlock">
			<Image
				align-self="auto"
				order="0"
				display="block"
				height="600px"
				margin="0px 0px 80px 0px"
				object-position="50% 75%"
				width="100%"
				src="https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/untitledcj-gm.jpeg?v=2022-08-06T22:37:31.520Z"
				flex="0 1 auto"
				object-fit="cover"
				srcSet="https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/untitledcj-gm.jpeg?v=2022-08-06T22%3A37%3A31.520Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/untitledcj-gm.jpeg?v=2022-08-06T22%3A37%3A31.520Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/untitledcj-gm.jpeg?v=2022-08-06T22%3A37%3A31.520Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/untitledcj-gm.jpeg?v=2022-08-06T22%3A37%3A31.520Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/untitledcj-gm.jpeg?v=2022-08-06T22%3A37%3A31.520Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/untitledcj-gm.jpeg?v=2022-08-06T22%3A37%3A31.520Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62e84f555e5c6e0021553ba6/images/untitledcj-gm.jpeg?v=2022-08-06T22%3A37%3A31.520Z&quality=85&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
			/>
			<Text
				sm-font="normal 700 32px/1.3 &quot;Inter&quot;, sans-serif"
				sm-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0"
				color="--lightD1"
				font="--headline2"
				md-text-align="center"
				md-font="normal 700 52px/1.1 &quot;Inter&quot;, sans-serif"
				sm-text-align="center"
				md-margin="0px 0px 0px 0px"
			>
				Error #404
				<br />
				The page you requested cannot be found.
				<br />
				This was probably a memory from our old website. Don't worry, our new website is much cooler.{" "}
			</Text>
		</Section>
		<Section background-color="--dark" text-align="center" padding="32px 0">
			<Menu
				display="flex"
				justify-content="center"
				font="--lead"
				font-weight="700"
				margin="-6px 0 16px"
				md-flex-direction="column"
				md-align-items="center"
			>
				<Override slot="link" text-decoration="none" color="--light" padding="6px 12px" />
				<Override slot="link-active" color="--primary" />
				<Override slot="item" padding="6px 0px" />
			</Menu>
			<Link
				href="mailto:team@nonfungiblealbums.io"
				text-decoration-line="none"
				variant="--base"
				color="--grey"
				hover-color="--primary"
				lg-font="300 16px --fontFamily-sans"
				lg-color="#b1c1d1"
			>
				team@nonfungiblealbums.io
			</Link>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"60da14fa3f4eb1001ea27687"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<style place={"endOfBody"} rawKey={"62f4bcf3ed0f76d8601086b7"}>
				{"a[href=\"https://quarkly.io/\"]{\ndisplay: none;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});