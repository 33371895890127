import React from "react";
import Index from "pages/index";
import Covers from "pages/covers";
import NewsInterviews from "pages/newsInterviews";
import Team from "pages/team";
import Page404 from "pages/page404";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        font-family: sans-serif;
    }
`;

export default () => (
    <Router>
        <GlobalStyles />
        <Switch>
        			<Route exact path='/' component={Index}/>
			<Route exact path='/index' component={Index}/>
			<Route exact path='/covers' component={Covers}/>
			<Route exact path='/news-interviews' component={NewsInterviews}/>
			<Route exact path='/team' component={Team}/>
			<Route component={Page404}/>
        </Switch>
    </Router>
);
