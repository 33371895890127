import React from "react";
import theme from "theme";
import { Theme, Link, Span, Text, Box, Section, Hr } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, Menu } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Non-Fungible Albums
			</title>
			<meta name={"description"} content={"A Future Classic for The NFT World."} />
			<meta property={"og:title"} content={"Non-Fungible Albums"} />
			<meta property={"og:description"} content={"A future classic of the NFT space."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/untitled2_04.jpeg?v=2022-08-06T22:58:14.411Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/Mall%20Walkers%20-%20We.jpeg?v=2022-08-06T22:37:12.712Z"} />
			<meta name={"msapplication-TileColor"} content={"#04080C"} />
		</Helmet>
		<Section padding="40px 0 40px 0" quarkly-title="Header">
			<Override
				slot="SectionContent"
				flex-direction="row"
				align-items="center"
				display="flex"
				justify-content="space-between"
			/>
			<Box
				empty-border-color="LightGray"
				width="30%"
				align-items="center"
				lg-width="50%"
				empty-min-height="64px"
				empty-border-style="solid"
				display="flex"
				sm-width="70%"
				empty-min-width="64px"
				empty-border-width="1px"
			>
				<Text color="--light" margin="0px 0px 0px 0px" font="--headline3">
					<Span border-color="#ffffff" color="#ffffff">
						Non-Fungible Albums
					</Span>
				</Text>
			</Box>
			<Box
				sm-width="30%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-style="solid"
				display="flex"
				align-items="center"
				empty-border-width="1px"
				empty-border-color="LightGray"
				width="70%"
				lg-width="50%"
			>
				<Components.QuarklycommunityKitMobileSidePanel>
					<Override slot="Content" justify-content="flex-end" background="--color-dark" padding="0px 0px 0px 0px" />
					<Override slot="Button Text" md-color="--light" md-font="--base" md-text-transform="uppercase">
						go
					</Override>
					<Override slot="Button Icon" md-color="--light" />
					<Box
						md-padding="20px 0px 20px 0px"
						display="flex"
						justify-content="flex-end"
						align-items="center"
						md-flex-direction="column"
					>
						<Link href="https://twitter.com/nonfngblealbums" color="#ffffff" padding="0px 10px 0px 10px">
							Twitter
						</Link>
						<Link href="https://discord.com/invite/NwFfUbuhks" color="#ffffff" padding="0px 10px 0px 10px">
							Discord
						</Link>
						<Link href="https://opensea.io/nonfungiblealbums?tab=created" color="#ffffff" padding="0px 10px 0px 10px">
							OpenSea
						</Link>
						<Link href="https://open.spotify.com/artist/33hDvJkOZe1zuhg43TxWBX?si=j3fqyl5HRS63hZl7TP-Apg" color="#ffffff" padding="0px 10px 0px 10px">
							Spotify
						</Link>
					</Box>
				</Components.QuarklycommunityKitMobileSidePanel>
			</Box>
		</Section>
		<Section padding="50px 0 100px 0" quarkly-title="HeroBlock" lazy-load>
			<Section
				background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(0,0,0,0) 26.2%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/greg_cd_glow-BLURANDSHARPENED_01.jpg?v=2022-09-18T11:32:17.041Z) center/cover"
				padding="60px 0"
				sm-padding="40px 0"
				color="--light"
				font="--base"
			>
				<Box margin="-16px -16px -16px -16px" display="flex">
					<Box display="flex" padding="16px 16px 16px 16px" width="75%" md-width="100%">
						<Box display="flex" flex-direction="column">
							<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
								WELCOME TO OUR UNIVERSE
							</Text>
							<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0">
								The music, the visual arts and stories we create for the megastars that we dreamt of and fabricated.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box text-align="center" margin="96px 0 0 0">
					<Text margin="8px 0" text-transform="uppercase" lg-font="normal 18px/27px Inter, sans-serif">
						A near future classıc
					</Text>
				</Box>
			</Section>
			<Text
				sm-margin="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0"
				color="--lightD1"
				md-font="normal 700 52px/1.1 &quot;Inter&quot;, sans-serif"
				sm-font="normal 700 32px/1.3 &quot;Inter&quot;, sans-serif"
				sm-text-align="center"
				font="--headline1"
				md-text-align="center"
				padding="040px 0px 6px 0px"
			>
				Everything that you see here are ours. We bypass the mundanities and problems of the real world. We create a more vibrant reality for artists and fans of art.
			</Text>
		</Section>
		<Section>
			<Override slot="SectionContent" />
			<Section
				background="linear-gradient(0deg,rgba(0,0,0,0) 46.2%,#000000 98.1%),--color-darkL2 url(https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/a6d2c1_96eff02a96664384b4cf2e2cc0dfb222_mv2.jpg?v=2022-09-30T09:42:32.117Z) center/cover"
				padding="60px 0"
				sm-padding="40px 0"
				color="--light"
				font="--base"
				height="900px"
			>
				<Box margin="-16px -16px -16px -16px" display="flex">
					<Box display="flex" padding="16px 16px 16px 16px" width="75%" md-width="100%">
						<Box display="flex" flex-direction="column" font="18px/27px ">
							<Text
								color="--lightD2"
								letter-spacing="1px"
								text-transform="uppercase"
								margin="0"
								font="12px/27px --fontFamily-googlePressStart2P"
							>
								<Link
									href="https://opensea.io/collection/nonfungiblecassettes"
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
									color="#babaff"
								>
									Non-Fungible cassettes
								</Link>
								:
								<br />
								2/3 series launched (10/15 nfts released)
								<br />
								10/10 of the released nfts sold
								<br />
								<br />
								<Link
									href="https://opensea.io/collection/nonfungiblecds"
									color="#babaff"
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									Non-Fungible cds
								</Link>
								:
								<br />
								2/3 series launched (10/15 nfts released)
								<br />
								9/10 of the released nfts sold
							</Text>
						</Box>
					</Box>
				</Box>
			</Section>
		</Section>
		<Section padding="50px 0 100px 0">
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
				flex-wrap="wrap"
				sm-flex-direction="column"
				empty-min-width="64px"
			>
				<Box
					display="flex"
					width="20%"
					sm-width="100%"
					sm-justify-content="flex-start"
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					sm-padding="0px 0px 16px 0px"
				>
					<Hr width="100%" color="--light" />
				</Box>
				<Box
					empty-min-width="64px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					display="flex"
					width="20%"
					empty-min-height="64px"
					empty-border-width="1px"
					justify-content="center"
					sm-width="100%"
					sm-justify-content="flex-start"
					sm-padding="0px 0px 16px 0px"
				>
					<Text color="--light" margin="0px 0px 0px 0px" font="normal 600 18px/1.5 --fontFamily-googleInter">
						Narrative
					</Text>
				</Box>
				<Box
					empty-border-style="solid"
					empty-border-color="LightGray"
					display="flex"
					width="60%"
					sm-justify-content="flex-start"
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
					sm-width="100%"
					sm-padding="0px 0px 16px 0px"
				>
					<Text
						color="--lightD1"
						margin="0px 0px 0px 0px"
						md-font="500 20px/1.3 --fontFamily-googleInter"
						sm-width="100%"
						font="--headline4"
					>
						Experience our music that we wrote and produced in a wide range of styles via our cassettes and CDs with different record labels, (like ETH Records, BTC Music, Sol Record Co.) unique barcodes, unique designs. We even write and produce the stories of our musicians and bands as{" "}
						<Link
							href="/news-interviews"
							color="#cacaff"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							radio, magazine interviews and newspaper news
						</Link>
						.
					</Text>
				</Box>
			</Box>
			<Box
				display="flex"
				margin="86px 0px 0px 0px"
				sm-flex-direction="column"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				sm-margin="32px 0px 0px 0px"
				empty-border-width="1px"
				empty-border-style="solid"
				flex-wrap="wrap"
			>
				<Box
					display="flex"
					width="20%"
					sm-width="100%"
					sm-justify-content="flex-start"
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					sm-padding="0px 0px 16px 0px"
				>
					<Hr width="100%" color="--light" />
				</Box>
				<Box
					empty-border-color="LightGray"
					display="flex"
					width="20%"
					sm-padding="0px 0px 16px 0px"
					empty-min-width="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					justify-content="center"
					sm-width="100%"
					sm-justify-content="flex-start"
					empty-min-height="64px"
				>
					<Text font="normal 600 18px/1.5 --fontFamily-googleInter" color="--light" margin="0px 0px 0px 0px">
						System
					</Text>
				</Box>
				<Box
					empty-border-color="LightGray"
					display="flex"
					sm-width="100%"
					sm-justify-content="flex-start"
					empty-min-height="64px"
					empty-border-width="1px"
					width="60%"
					sm-padding="0px 0px 16px 0px"
					empty-min-width="64px"
					empty-border-style="solid"
				>
					<Text font="--headline4" color="--lightD1" margin="0px 0px 0px 0px" md-font="500 20px/1.3 --fontFamily-googleInter">
						The stories behind the music and their respective artists will expand further with the follow up collections – all of which will preserve the uniqueness of the original collections. Every collection will add value and pay homage to each other.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(0,0,0,0) 27.1%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/untitled2-editJPG.jpeg?v=2022-08-06T22:36:57.996Z) center/cover"
			padding="60px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
			height="900px"
		>
			<Box margin="-16px -16px -16px -16px" display="flex">
				<Box display="flex" padding="16px 16px 16px 16px" width="45%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
							non-fungıble cassettes and non-fungıble cds
						</Text>
						<Text
							as="h1"
							font="normal 700 65px/1.1 &quot;Inter&quot;, sans-serif"
							md-font="--headline2"
							margin="10px 0"
							width="50% content-box"
							lg-font="normal 700 55px/1.1 &quot;Inter&quot;, sans-serif"
						>
							Our ERC-721 contracts, listed on OpenSea.
							<br />
							Our project wallet is ice-cold, that means super-safe.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="50px 0 100px 0">
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
				flex-wrap="wrap"
				margin="0px 0px 46px 0px"
			>
				<Box
					sm-width="20%"
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					display="flex"
					width="35%"
					lg-justify-content="space-between"
				>
					<Text color="--light" margin="0px 0px 0px 0px" font="normal 600 18px/1.5 --fontFamily-googleInter" lg-text-align="left">
						01
					</Text>
				</Box>
				<Box
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					display="flex"
					width="65%"
				>
					<Text
						color="--lightD1"
						margin="0px 0px 0px 0px"
						width="100%"
						md-font="normal 700 52px/1.1 &quot;Inter&quot;, sans-serif"
						sm-font="normal 700 38px/1.1 &quot;Inter&quot;, sans-serif"
						font="--headline1"
					>
						<Link
							href="https://opensea.io/collection/nonfungiblecassettes"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							color="#c4c2ff"
							text-decoration-line="underline"
							border-color="#ffffff"
						>
							Cassettes
						</Link>
						{" "}and{" "}
						<Link
							href="https://opensea.io/collection/nonfungiblecds"
							color="#c8c8ff"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							border-color="#ffffff"
						>
							CDs
						</Link>
						<br />
						<Span
							lg-font="700 20px/0.8 Inter, sans-serif"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							font="700 20px/1 Inter, sans-serif"
						>
							OG collections with their own ERC-721 contracts, listed on OpenSea.
						</Span>
					</Text>
				</Box>
			</Box>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
				flex-wrap="wrap"
				margin="0px 0px 46px 0px"
			>
				<Box
					sm-width="20%"
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					display="flex"
					width="35%"
				>
					<Text color="--light" margin="0px 0px 0px 0px" font="normal 600 18px/1.5 --fontFamily-googleInter">
						02
					</Text>
				</Box>
				<Box
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					display="flex"
					width="65%"
				>
					<Text
						color="--lightD1"
						margin="0px 0px 0px 0px"
						width="100%"
						md-font="normal 700 52px/1.1 &quot;Inter&quot;, sans-serif"
						sm-font="normal 700 38px/1.1 &quot;Inter&quot;, sans-serif"
						font="--headline1"
						lg-height="50% content-box"
					>
						<Link
							href="/covers"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							color="#c8c8ff"
							link-color="#c8c8ff"
						>
							<Span
								link-border-color="#cfcff7"
								link-color="#eeeeff"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Cover Arts
								<br />
							</Span>
						</Link>
						<Span
							lg-font="700 20px/0.8 Inter, sans-serif"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							font="700 20px/1 Inter, sans-serif"
						>
							By leading visual artists from the NFT space. Click the title to see the artists.
						</Span>
					</Text>
				</Box>
			</Box>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
				flex-wrap="wrap"
				margin="0px 0px 46px 0px"
			>
				<Box
					sm-width="20%"
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					display="flex"
					width="35%"
				>
					<Text color="--light" margin="0px 0px 0px 0px" font="normal 600 18px/1.5 --fontFamily-googleInter">
						03
					</Text>
				</Box>
				<Box
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					display="flex"
					width="65%"
				>
					<Text
						color="--lightD1"
						margin="0px 0px 0px 0px"
						width="100%"
						md-font="normal 700 52px/1.1 &quot;Inter&quot;, sans-serif"
						sm-font="normal 700 38px/1.1 &quot;Inter&quot;, sans-serif"
						font="--headline1"
						lg-height="50% content-box"
					>
						Vinyls
						<br />
						<Span
							lg-font="700 20px/0.8 Inter, sans-serif"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							font="700 20px/1 Inter, sans-serif"
						>
							Third OG collection... Third and final edition of the albums' title tracks after the cassettes and CDs. Holding the cassette and CD of an album will get you the Vinyl edition via airdrop!
						</Span>
					</Text>
				</Box>
			</Box>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
				flex-wrap="wrap"
				margin="0px 0px 46px 0px"
			>
				<Box
					sm-width="20%"
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					display="flex"
					width="35%"
				>
					<Text color="--light" margin="0px 0px 0px 0px" font="normal 600 18px/1.5 --fontFamily-googleInter">
						04
					</Text>
				</Box>
				<Box
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					display="flex"
					width="65%"
				>
					<Text
						color="--lightD1"
						margin="0px 0px 0px 0px"
						width="100%"
						md-font="normal 700 52px/1.1 &quot;Inter&quot;, sans-serif"
						sm-font="normal 700 38px/1.1 &quot;Inter&quot;, sans-serif"
						font="--headline1"
						lg-height="50% content-box"
					>
						Vocal Versions
						<br />
						<Span
							lg-font="700 20px/0.8 Inter, sans-serif"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							font="700 20px/1 Inter, sans-serif"
						>
							The tracks of the NFTs are the title tracks, but instrumental... It will not be like that for a long time! The voices of all our fictional musicians will be revealed and you will hear the songs with their lyrics and vocal versions. The NFTs will be updated with no fee paid by the collectors. This phase will show the power of musicianship and universe creation of the project even further.
						</Span>
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(0,0,0,0) 26.2%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/62e84f555e5c6e0021553ba6/images/untitled2_04.jpeg?v=2022-08-06T22:58:14.411Z) center/cover"
			padding="60px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
			height="900px"
		>
			<Box margin="-16px -16px -16px -16px" display="flex">
				<Box display="flex" padding="16px 16px 16px 16px" width="50%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
							Non-fungıble albums and ıts future communıty
						</Text>
						<Text
							as="h1"
							font="--headline1"
							md-font="--headline2"
							margin="10px 0"
							lg-font="normal 700 62px/1.1 &quot;Inter&quot;, sans-serif"
						>
							We intend to become a pioneer with our narrative and system for the NFT space.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="50px 0 100px 0">
			<Box
				empty-border-color="LightGray"
				display="flex"
				flex-wrap="wrap"
				sm-flex-direction="column"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Box
					sm-justify-content="flex-start"
					empty-min-width="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					width="20%"
					empty-min-height="64px"
					display="flex"
					sm-width="100%"
					sm-padding="0px 0px 16px 0px"
				>
					<Hr width="100%" color="--light" />
				</Box>
				<Box
					empty-border-width="1px"
					empty-border-style="solid"
					display="flex"
					justify-content="center"
					sm-width="100%"
					sm-padding="0px 0px 16px 0px"
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-color="LightGray"
					width="20%"
					sm-justify-content="flex-start"
				>
					<Text font="normal 600 18px/1.5 --fontFamily-googleInter" color="--light" margin="0px 0px 0px 0px">
						Stay
					</Text>
				</Box>
				<Box
					empty-border-color="LightGray"
					display="flex"
					sm-width="100%"
					sm-justify-content="flex-start"
					sm-padding="0px 0px 16px 0px"
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					width="60%"
				>
					<Text
						font="--headline4"
						color="--lightD1"
						margin="0px 0px 0px 0px"
						md-font="500 20px/1.3 --fontFamily-googleInter"
						sm-width="100%"
					>
						We are not using the word "community" lightly. The cover arts collection is created by leading visual artists of the NFT space.
						<br />
						The music NFTs of the album tracks will be multi edition music NFTs created by a big team of musicians. In both collections, the creators will have 95% royalty. That, is a true creator community and proof that we are not in this only for ourselves. We're in this for art, artists and to become a classic for the NFT world.
					</Text>
				</Box>
			</Box>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				flex-wrap="wrap"
				sm-margin="32px 0px 0px 0px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
				margin="86px 0px 0px 0px"
				sm-flex-direction="column"
			>
				<Box
					display="flex"
					sm-padding="0px 0px 16px 0px"
					empty-min-width="64px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					width="20%"
					sm-width="100%"
					sm-justify-content="flex-start"
					empty-min-height="64px"
					empty-border-width="1px"
				>
					<Hr width="100%" color="--light" />
				</Box>
				<Box
					sm-padding="0px 0px 16px 0px"
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
					empty-border-color="LightGray"
					display="flex"
					justify-content="center"
					empty-border-style="solid"
					width="20%"
					sm-width="100%"
					sm-justify-content="flex-start"
				>
					<Text font="normal 600 18px/1.5 --fontFamily-googleInter" color="--light" margin="0px 0px 0px 0px">
						With Us
					</Text>
				</Box>
				<Box
					empty-border-width="1px"
					empty-border-color="LightGray"
					display="flex"
					sm-justify-content="flex-start"
					sm-padding="0px 0px 16px 0px"
					empty-min-height="64px"
					empty-border-style="solid"
					width="60%"
					sm-width="100%"
					empty-min-width="64px"
				>
					<Text font="--headline4" color="--light" margin="0px 0px 0px 0px" md-font="500 20px/1.3 --fontFamily-googleInter">
						<Link
							href="/team"
							link-color="#cacaff"
							color="#cacaff"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							visited-color="#cacaff"
						>
							Our team
						</Link>
						{" "}is doxxed. Other than being the 3d artist of NFA,{" "}
						<Link
							href="https://lynkfire.com/can"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							color="#cacaff"
						>
							Can Artsan
						</Link>
						{" "}is the music composer and producer of the OG collections; Non-Fungible Cassettes, Non-Fungible CDs and Non-Fungible Vinlys. He is a Berklee graduate musician, his real name is{" "}
						<Link
							href="https://mahircankucuk.com"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							color="#cacaff"
						>
							Mahir Can Küçük
						</Link>
						.
						<br />
						<Link
							href="https://behance.net/merttanatmis"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							color="#cacaff"
						>
							Mert Tanatmis
						</Link>
						{" "}is our 2D designer. He is a Toronto based UX designer working for Commersive Solutions.
					</Text>
				</Box>
			</Box>
		</Section>
		<Box
			empty-min-width="64px"
			empty-min-height="64px"
			empty-border-width="1px"
			empty-border-style="solid"
			empty-border-color="LightGray"
			display="flex"
			height="180px"
			sm-height="100% content-box"
			sm-width="auto"
			md-width="max-content"
			padding="40px 0px 40px 20px"
			width="auto"
			lg-height="auto"
		>
			<Text
				color="--lightD1"
				margin="0px 0px 0px 0px"
				width="160%"
				md-font="normal 700 47px/1.1 &quot;Inter&quot;, sans-serif"
				sm-font="normal 700 38px/1.4 &quot;Inter&quot;, sans-serif"
				font="--headline1"
				lg-height="auto"
				sm-max-width="100%"
				text-align="center"
				sm-text-align="left"
				lg-text-align="left"
			>
				Main Teaser!
			</Text>
		</Box>
		<Section sm-height="auto" height="768px" md-height="440px" lg-height="600px">
			<Override slot="SectionContent" height="900px" width="1200px" sm-height="auto" />
			<Components.QuarklycommunityKitYouTube
				url="https://www.youtube.com/watch?v=4Lils-HQg0g&"
				width="50% content-box"
				display="flex"
				flex-direction="row"
				max-width="100%"
				height="300px"
				padding="0px 0px 56.25% 0px"
				sm-padding="0px 0px 56.25% 0px"
			>
				<Override slot="YouTube Content" display="flex" align-items="center" justify-content="center" />
			</Components.QuarklycommunityKitYouTube>
		</Section>
		<Box
			empty-min-width="64px"
			empty-min-height="64px"
			empty-border-width="1px"
			empty-border-style="solid"
			empty-border-color="LightGray"
			display="flex"
			height="180px"
			sm-height="100% content-box"
			sm-width="auto"
			md-width="max-content"
			padding="40px 0px 40px 20px"
			width="auto"
			lg-height="auto"
		>
			<Text
				color="--lightD1"
				margin="0px 0px 0px 0px"
				width="160%"
				md-font="normal 700 47px/1.1 &quot;Inter&quot;, sans-serif"
				sm-font="normal 700 38px/1.4 &quot;Inter&quot;, sans-serif"
				font="--headline1"
				lg-height="auto"
				sm-max-width="100%"
				text-align="center"
				sm-text-align="left"
				lg-text-align="left"
			>
				A TV Program from 1984!
			</Text>
		</Box>
		<Section sm-height="auto">
			<Components.QuarklycommunityKitYouTube
				url="https://www.youtube.com/watch?v=cuKGSqKqsCU"
				width="50% content-box"
				display="flex"
				flex-direction="row"
				max-width="100%"
				padding="0px 0px 56.25% 0px"
				sm-padding="0px 0px 56.25% 0px"
			>
				<Override slot="YouTube Content" display="flex" align-items="center" justify-content="center" />
			</Components.QuarklycommunityKitYouTube>
		</Section>
		<Box
			empty-min-width="64px"
			empty-min-height="64px"
			empty-border-width="1px"
			empty-border-style="solid"
			empty-border-color="LightGray"
			display="flex"
			height="180px"
			sm-height="100% content-box"
			sm-width="auto"
			md-width="max-content"
			padding="40px 0px 40px 20px"
			width="auto"
			lg-height="auto"
		>
			<Text
				color="--lightD1"
				margin="0px 0px 0px 0px"
				width="160%"
				md-font="normal 700 47px/1.1 &quot;Inter&quot;, sans-serif"
				sm-font="normal 700 38px/1.4 &quot;Inter&quot;, sans-serif"
				font="--headline1"
				lg-height="auto"
				sm-max-width="100%"
				text-align="center"
				sm-text-align="left"
				lg-text-align="left"
			>
				Another one from 1988!
			</Text>
		</Box>
		<Section sm-height="auto">
			<Components.QuarklycommunityKitYouTube
				url="https://youtu.be/Zkr9YXQ_1p0"
				display="flex"
				flex-direction="row"
				padding="0px 0px 56.25% 0px"
				width="auto"
				max-width="100%"
				sm-padding="0px 0px 56.25% 0px"
			>
				<Override slot="YouTube Content" display="flex" align-items="center" justify-content="center" />
			</Components.QuarklycommunityKitYouTube>
		</Section>
		<Section background-color="--dark" text-align="center" padding="32px 0">
			<Override slot="SectionContent" font="20px sans-serif" />
			<Menu
				display="flex"
				justify-content="center"
				font="--lead"
				font-weight="700"
				margin="-6px 0 16px"
				md-flex-direction="column"
				md-align-items="center"
			>
				<Override slot="link" text-decoration="none" color="--light" padding="6px 12px" />
				<Override slot="link-active" color="--primary" />
				<Override slot="item" padding="6px 0px" />
			</Menu>
			<Link
				href="mailto:team@nonfungiblealbums.io"
				text-decoration-line="none"
				variant="--base"
				color="--grey"
				hover-color="--primary"
				lg-font="300 16px --fontFamily-sans"
				lg-color="#b1c1d1"
			>
				team@nonfungiblealbums.io
			</Link>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"60da14fa3f4eb1001ea27687"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<style place={"endOfBody"} rawKey={"62f4bcf3ed0f76d8601086b7"}>
				{"a[href=\"https://quarkly.io/\"]{\ndisplay: none;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});